import isEmptyObject from 'is-empty-object'

import { getActiveCart } from '@/redux/cart/selectors'
import { DEFAULT_MENU_SLUG } from '@helpers/constants'
import { getReferralSource } from 'redux/app/selectors'
import { getCheckoutError } from 'redux/checkout/selectors'
import { getEta } from 'redux/eta/selectors'
import { getActiveLocation, getActiveDepot } from 'redux/location/selectors'
import { getOrderPaymentMethod } from 'redux/order/selectors'
import { getPromoCode } from 'redux/promo/selectors'
import { getQuote } from 'redux/quote/selectors'


/**
 * Commonly used methods to transform state to analytics event objects
 */

export function getMenuPayload(state, menuSlug = '') {
  const location = state.location?.activeLocation
  const eta = state.eta
  const depot = location?.depot || { dispensary: {} }
  const coordinates = location?.coordinates || {}
  const { basic = {}, status = {}, extra = {} } = state.profile
  const slug = menuSlug || state.menu.menus?.slug || DEFAULT_MENU_SLUG

  // Removed catalog.items from the payload for Catalog Pages - array of unique productIds from the menu response
  // We looped thru menu.groups and then each group.products to build this array
  let payload = {
    'catalog.options.zip': location?.zip,
    'catalog.options.city': location?.city,
    'catalog.options.address': location?.street,
    'catalog.options.location.coordinates.latitude': coordinates.latitude,
    'catalog.options.location.coordinates.longitude': coordinates.longitude,
    'catalog.dispensary.depot.id': depot.id,
    'catalog.dispensary.id': (depot.dispensary || {}).id,
    'delivery.available': !eta.isRestricted,
    'delivery.reason': eta.reason,
    menu_name: slug,
    source: getReferralSource(state),
  }

  if (!isEmptyObject(basic)) {
    payload = Object.assign({}, payload, {
      'user.authenticated': true,
      'user.status': status && status.verificationStatusDescription,
      'user.credit': extra && extra.inviteCredit
    })
  }

  return payload
}

export function getPayloadFromState(state) {
  const cart = getActiveCart(state)
  const quote = getQuote(state)
  const promoCode = getPromoCode(state)
  const paymentMethod = getOrderPaymentMethod(state)
  const location = getActiveLocation(state)
  const depot = getActiveDepot(state)
  const eta = getEta(state)

  const {
    priceInfo: {
      chargeAmount,
      deliveryFee,
      bypassDeliveryFeeReason,
      safetyFee,
      subtotal,
      taxLineItems,
      totalTax,
      totalPrice,
      veteranDiscount,
      keepTheChange,
      minimumAmountBypassDeliveryFee,
      remainingAmountBypassDeliveryFee,
      promoCredit,
      inviteCredit
    }
  } = quote

  return {
    availablePaymentMethods: depot.payment,
    bypassDeliveryFeeReason,
    cardError: null,
    cart: cart.products,
    cartId: quote.cartId,
    'cart.size': cart.products.length,
    chargeAmount,
    city: location.city,
    'delivery.available': !eta.isRestricted,
    'delivery.eta': eta.estimatedDeliveryTime,
    'delivery.reason': eta.reason,
    deliveryFee,
    depotId: quote.depotId,
    error: getCheckoutError(state),
    'eta.deliveryType': eta.deliveryType,
    inviteCredit,
    keepTheChange,
    'location.coordinates': location.coordinates,
    'location.id': location.id,
    menu_name: state.menu.menus?.slug,
    minimumAmountBypassDeliveryFee,
    paymentMethod,
    promoCode,
    promoCredit,
    remainingAmountBypassDeliveryFee,
    safetyFee,
    source: getReferralSource(state),
    subtotal,
    taxLineItems,
    total: chargeAmount,
    totalPrice,
    totalTax,
    usedLocationForBilling: false,
    'user.authenticated': Boolean(state.user.userId),
    veteranDiscount,
    zip: location.zip
  }
}
