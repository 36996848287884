import type { IncomingHttpHeaders } from 'http'

// OG caches it menus response for 10 mins - setting our expiration limit to the same time
export const MENU_CACHE_EXPIRATION = 10 * 60 // 10 mins in seconds

export const DISABLE_BFF_DATA_LOGGING = process.env.DISABLE_BFF_DATA_LOGGING

export const SERVER_API_URL = `${process.env.API_URL}/api` // @TODO rename this to PINE_API_URL
export const GEOLOCALITY_API_URL = `${process.env.GEOLOCALITY_URL}/api`

const dev = process.env.NODE_ENV !== 'production'
export const WWW_BASE_URL = dev ? 'https://localhost:3000' : process.env.WWW_BASE_URL
export const NEXTJS_API_PATH = '/api/v2'

const iOS = /iPad|iPhone|iPod/
const android = /android/i
const webview = /eaze-webview/

const detectUserAgent = (regex: RegExp, headers: IncomingHttpHeaders) => regex.test(headers?.['user-agent'] || '')

export const isIosApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(iOS, headers) && detectUserAgent(webview, headers)
export const isAndroidApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(android, headers) && detectUserAgent(webview, headers)
export const isConsumerApp = (headers: IncomingHttpHeaders) => detectUserAgent(webview, headers)

export const VAPORIZER_REGEX = /(vap(orizer|orization|e|or){1}s*\s*)/gi

export const DEFAULT_CHUNK_SIZE = 50

// Title of vaporizer category on iOS webview app for passing anti-vape app reviewers
export const IOS_VAPORIZER_CATEGORY_NAME = 'Cartridges'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pick = (object: any, keys: string[]): any => {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const SPLIT_EXPERIMENTS = {
  UPSELL_AT_CART: 'upsell_cart'
}

export const SPLIT_TREATMENTS = {
  UPSELL_AT_CART: {
    ON: 'on',
    OFF: 'off'
  }
}

// Breaks one large array into smaller chunks to send large payloads of data
export function chunkArray(array: any, chunkSize: number) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}
