import { createSelector } from 'reselect'

import { RootState } from '../reducers'

const cookieHydration = (state: RootState) => state.app.cookieHydrate
const localStorageHydration = (state: RootState) => state.app.hydrated

export const getHasHydrated = createSelector(
  [cookieHydration, localStorageHydration],
  (cookie, localStorage) => cookie && localStorage
)

export const getReferralSource = (state: RootState) => state.app.referralSource
